<template>
  <div ref="modal" class="modal">
    <a-modal
        v-model:visible="visible"
        centered
        width="500px"
        :footer="null"
        :closable="false"
        :get-container="() => $refs.modal"
    >
      <div class="select">
        <div class="mWrap">
          <div class="scroll">
            <div class="title">Recipient Details</div>
            <div class="line"></div>
            <div class="list">
              <a-radio-group v-model:value="checked">
                <div v-for="(item,index) in addressList" :key="index" class="item">
                  <div class="receiver">
                    <a-radio :value="item.id">{{ item.u_name }}</a-radio>
                    <div class="edit" @click="openEdit(item.id)">Edit</div>
                  </div>
                  <div class="info">
                    <div>{{ item.mobile }}</div>
                    <div>{{ item.login_email }}</div>
                    <div>
                      {{ item.country_name + ' ' + item.area_line_1 + ' ' + item.area_line_2 + ' ' + item.city + ' ' + item.province }}
                    </div>
                  </div>
                  <div class="border"></div>
                </div>
              </a-radio-group>
            </div>
            <div class="add" @click="openAdd">
              <img src="@/assets/imgs/icon_add1.png">
              <div class="text">Add New Address</div>
            </div>
          </div>
        </div>
        <div class="btnWrap">
          <div class="btn" @click="visible = false">Cancel</div>
          <div class="btn confirm" @click="handleBuy">Confirm</div>
        </div>
      </div>
    </a-modal>
  </div>

  <!--添加地址弹框-->
  <AddAddress ref="add" :contract="contract" @added="getList"/>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { getUserAddress } from '@/request/api'
  import AddAddress from '@/views/Mine/AddAddress'
  import { message } from 'ant-design-vue'

  const router = useRouter()
  const visible = ref(false)
  const checked = ref(1)
  const addressList = ref([])

  defineExpose({ visible, getList })

  const props = defineProps(['contract', 'goods_id'])

  // 确认购买
  let zuDisable = false
  async function handleBuy() {
    if(zuDisable) {
      return
    }
    zuDisable = true
    const { gasPrice, blockNftContract, conContract } = props.contract
    try {
      await blockNftContract.setApprovalForAll(contractConfig.conAddress, true, {
        gasPrice: gasPrice,
        gasLimit: 400000
      })
      const res = await conContract.Placeanorderfortheproduct(props.goods_id, checked.value, {
        gasPrice,
        gasLimit: 400000
      })
      if(res) {
        zuDisable = false
        setTimeout(() => {
          message.success('Operation Successful')
          router.push('/success')
        }, 2000)
      }
    } catch (e) {
      zuDisable = false
      message.error('Operation Failed')
    }
  }

  function getList() {
    getUserAddress({
      address: props.contract.address
    }).then(res => {
      if(res.success) {
        addressList.value = res.data
        checked.value = res.data[0].id
      }
    })
  }

  const add = ref(null)
  function openAdd() {
    add.value.visible = true
    add.value.getAreaCode()
    add.value.getCountries()
  }

  function openEdit(id) {
    add.value.visible = true
    add.value.getAreaCode()
    add.value.getCountries()
    add.value.aId = id
  }
</script>

<style lang="less" scoped>
.modal{
  ::v-deep .ant-modal-content{
    background: transparent;
    box-shadow: none;

    .ant-modal-body{
      padding: 0;
    }
  }
  ::v-deep .ant-modal-mask{
    background: rgba(0, 0, 0, 0.8);
  }
  .select{
    color: #ffffff;

    .mWrap {
      height: 500px;
      margin-bottom: 24px;
      padding: 26px 0;

      .scroll {
        height: 100%;
        overflow-y: auto;

        .title{
          font-size: 22px;
          text-align: center;
          line-height: 20px;
          margin-bottom: 23px;
        }
        .line{
          margin: 0 16px 36px 16px;
          border-radius: 12px;
          height: 2px;
          background: radial-gradient(50% 50% at 50% 50.00%, #949494 78.65%, #000 100%);
        }
        .list{
          padding: 0 20px;
          margin-bottom: 20px;

          .item{
            padding: 0 12px;
            margin-bottom: 16px;

            .receiver{
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 6px;

              .edit{
                font-size: 17px;
                color: #06C8A1;
                cursor: pointer;
              }
            }
            .info{
              color: #697377;
              font-size: 15px;
              line-height: 23px;
              padding-left: 32px;
              margin-bottom: 10px;
            }
            .border{
              height: 0.7px;
              border-radius: 12px;
              background: radial-gradient(50% 50% at 50% 50.00%, #949494 78.65%, #000 100%);
            }
          }
          .item:last-child{
            margin-bottom: 0;
          }
        }
        .add{
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img{
            width: 28px;
            margin-right: 13px;
          }
          .text{
            font-size: 17px;
            color: #F8F8F8;
          }
        }
      }
    }
    .btnWrap{
      display: flex;
      justify-content: center;

      .btn{
        width: 112px;
        height: 44px;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #697377;
        border-radius: 18px;
        border: 1.5px solid #ffffff;
        color: #ffffff;
        cursor: pointer;
      }
      .confirm{
        margin-left: 60px;
        border: none;
        background: #06C8A1;
      }
    }
  }
}
</style>
