<template>
  <div class="product">
    <div class="container">
      <Menu/>
      <div class="main">
        <Header @getContract="getContract"/>
        <div class="welcome">
          <div class="left">
            <span>Redeem,</span> My Product
          </div>
          <div class="right">
            <div>{{ nft.name }} NFT</div>
            <div class="line"></div>
            <div>Balance: {{ nftBanner }}</div>
          </div>
        </div>
        <div class="banner bWrap">
          <div class="bg">
            <div class="voucher">
              <img src="@/assets/imgs/icon_voucher.png">
              <div class="text">
                Your Vouchers<span>Start Earning Upoints</span>
              </div>
            </div>
            <div class="title">Select to Redeem a product<br/>with your voucher.</div>
            <div class="tips">Please select confirm to proceed</div>
            <div class="nft">
              <div class="text">
                <div class="name">{{ nft.name }}</div>
                <div class="price">${{ nft.price }}</div>
                <div class="new">{{ nft.type }}</div>
                <div class="balance">
                  <img src="@/assets/imgs/icon_star.png">
                  <div class="num">Balance: {{ nftBanner }}</div>
                </div>
              </div>
              <img class="nftImg" :src="nft.imgUrl">
            </div>
          </div>
        </div>
        <div class="wrap">
          <div v-if="goods.length" class="list">
            <div
                v-for="(item,index) in goods"
                :key="index"
                :class="{active: goodsId == item.id}"
                class="item bWrap"
                @click="handleSelect(item.id)"
            >
              <div class="bg">
                <img class="icon" :src="item.images">
                <div class="name">{{ item.name }}</div>
                <img class="img" :src="item.pc_image">
              </div>
            </div>
          </div>
          <div v-if="goods.length" class="btn" @click="openSelect">Confirm</div>
          <div v-else class="noData">No data available</div>
        </div>
      </div>
    </div>

    <!--选择收货地址弹框-->
    <SelectAddress ref="select" :goods_id="goodsId" :contract="contract" />
  </div>
</template>

<script setup>
  import SelectAddress from './SelectAddress'
  import { ref, computed, provide } from 'vue'
  import { useRoute } from 'vue-router'
  import { goodList } from '@/request/api'
  import { message } from 'ant-design-vue'

  const route = useRoute()
  const nft_id = route.query.id

  const nftArr= [
    { id: 22, name: 'META BASE', price: 50, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft11.png') },
    { id: 30, name: 'RAMS EXPLORER', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft1.png') },
    { id: 28, name: 'Play 2 Earn', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft14.png') },
    { id: 23, name: 'META NET', price: 100, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft12.png') },
    { id: 11, name: 'EXPLORER', price: 100, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft1.png') },
    { id: 18, name: 'DIGITAL BANK ACC', price: 100, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft8.png') },
    { id: 26, name: 'META CRYPTO', price: 200, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft13.png') },
    { id: 24, name: 'MY WORLD FX', price: 200, type: 'BASIC NFT', imgUrl: require('@/assets/imgs/nft9.png') },
    { id: 27, name: 'MY WORLD FX', price: 200, type: 'TESTING NFT', imgUrl: require('@/assets/imgs/nft9.png') },
    { id: 29, name: 'RAMS DISCOVERER', price: 300, type: 'VOUCHER', imgUrl: require('@/assets/imgs/nft15.png') },
    { id: 12, name: 'ADVENTURER', price: 500, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft2.png') },
    { id: 25, name: 'MY WORLD FX', price: 600, type:'PREMIUM NFT', imgUrl: require('@/assets/imgs/nft10.png') },
    { id: 13, name: 'INFLUENCER', price: 1000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft3.png') },
    { id: 14, name: 'PIONEER', price: 3000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft4.png') },
    { id: 15, name: 'NAVIGATOR', price: 5000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft5.png') },
    { id: 16, name: 'MASTER', price: 10000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft6.png') },
    { id: 17, name: 'LEGEND', price: 20000, type: 'NEW NFT', imgUrl: require('@/assets/imgs/nft7.png') }
  ]

  const nftBanner = ref('')
  const contract = ref({})
  async function getContract(obj) {
    contract.value = obj
    nftBanner.value = parseInt(await obj.blockNftContract.balanceOf(obj.address, nft_id))
  }
  const nft = ref({})
  for (let val of nftArr) {
    if(val.id == nft_id) {
      nft.value = val
      break
    }
  }

  const select = ref(null)
  function openSelect() {
    if(!goodsId.value) {
      message.warning('Please select the product')
      return
    }
    select.value.visible = true
    select.value.getList()
  }

  const goodsId = ref('')
  function handleSelect(id){
    goodsId.value = id
  }

  const goods = ref([])
  goodList({ nft_id }).then(res => {
    if(res.success) {
      goods.value = res.data
    }
  })
</script>

<style lang="less" scoped>
.product{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

  .container {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .main {
      width: 888px;
      padding-top: 20px;

      .welcome{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 54px 0 27px 0;

        .left{
          font-size: 33px;

          span{
            color: #697377;
          }
        }
        .right{
          display: flex;
          align-items: center;
          font-size: 17px;

          .line{
            width: 0.7px;
            height: 20px;
            background: #ffffff;
            margin: 0 20px
          }
        }
      }
      .banner{
        height: 206px;
        margin-bottom: 60px;

        .bg{
          height: 100%;
          background: url("~@/assets/imgs/product.png") no-repeat right top;
          background-size: auto 100%;
          position: relative;
          padding: 20px 0 0 20px;

          .voucher{
            display: flex;
            align-items: center;
            margin-bottom: 26px;

            img{
              width: 26px;
              margin-right: 15px;
            }
            .text{
              font-size: 17px;

              span{
                font-size: 12px;
                color: #697377;
                margin-left: 10px;
              }
            }
          }
          .title{
            font-size: 28px;
            line-height: 31px;
            padding-left: 6px;
            margin-bottom: 8px;
          }
          .tips{
            font-size: 17px;
            color: #697377;
            padding-left: 6px;
          }
          .nft{
            width: 255px;
            height: 124px;
            position: absolute;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            background: url("~@/assets/imgs/nft_bg1.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            padding-left: 22px;
            color: #697377;

            .text{
              .name{
                font-size: 13px;
                color: #000000;
                font-weight: bold;
              }
              .price{
                font-size: 20px;
                color: #06C8A1;
                font-weight: bold;
                margin: 6px 0 4px 0;
              }
              .new{
                font-size: 12px;
                margin-bottom: 10px;
              }
              .balance{
                display: flex;
                align-items: center;
                font-size: 12px;

                img{
                  width: 20px;
                  margin-right: 5px;
                }
              }
            }
            .nftImg{
              width: 122px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 14px;
            }
          }
        }
      }
      .wrap{
        min-height: calc(100vh - 476px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 40px;
      }
      .list{
        display: flex;
        flex-wrap: wrap;
        padding: 0 2px;

        .item{
          width: 200px;
          height: 285px;
          margin: 0 28px 60px 0;
          cursor: pointer;

          .bg{
            position: relative;
            border-radius: 10px;
            height: 100%;
            background: linear-gradient(180deg, #1B1B1B 0%, #000 100%);
            padding-top: 40px;
            text-align: center;

            .icon{
              width: 52px;
              height: 52px;
              border-radius: 17px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: -26px;
            }
            .name{
              font-size: 17px;
              margin-bottom: 38px;
            }
            .img{
              width: 146px;
              height: 146px;
              margin: 0 auto;
            }
          }
        }
        .item:nth-child(4n) {
          margin-right: 0;
        }
        .item:nth-last-of-type(-n+4) {
          margin-bottom: 0;
        }
        .active{
          background-image: linear-gradient(180deg,  #000000, #000000), linear-gradient(180deg, #06C8A1, transparent 60%, transparent 100%);

          .bg{
            background: linear-gradient(180deg, rgba(6, 200, 161, 0.10) 0%, rgba(6, 200, 161, 0.00) 100%);
          }
        }
      }
      .btn{
        width: 200px;
        height: 32px;
        border-radius: 9px;
        background: #06C8A1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #000000;
        font-family: "DM Sans Bold";
        margin: 48px auto 0 auto;
        cursor: pointer;
      }
      .noData{
        text-align: center;
      }
    }
  }
}
</style>
